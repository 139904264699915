import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
 
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const SecondSlider = (props) => {
    const { data } = props;
    const [allData, setAllData] = useState({});
    
    useEffect(() => {
        if(data.Contents) {
            let secondSliderData = data.Contents.filter(item => item.Key.includes("home-second-slider"));
            let Label = secondSliderData.find(item => item.Key.includes("label"));
            setAllData(allData => ({
                ...allData,
                Label: Label ? Label.Value : "ZL EZATA",
            }))

            let Title = secondSliderData.find(item => item.Key.includes("title")).Value;
            let Paragraphs = secondSliderData.filter(item => item.Key.includes("paragraph")).map(item => item.Value);            
            let IMG = secondSliderData.filter(item => item.Key.includes("image")).map(item => item.Value);            
            let objList = [];
            for(let i = 0; i < 3; i++) {
                let obj = {
                    Title,
                    Paragraphs,
                    IMG,
                }               
                objList.push(obj); 
                setAllData(allData => ({
                    ...allData,
                    objList,
                }))
            }            
        }
    }, [data])
    
    return (
        <section className="slider__two">
            <div className="def__label__box container-70">
                <div className="rectangle"></div>
                <p className="label">{allData.Label}</p>
            </div>
            <Slider {...settings}>
                {allData.objList ? allData.objList.map((item, index) => (
                    <div key={index} className="slider__item container-70">
                        <div>
                            <h3 className="slider__title">{item.Title}</h3>
                            {item.Paragraphs.map((item, index) => (
                                <p key={index} className="slider__paragraph">{item}</p>

                            ))}
                        </div>
                        <img src={item.IMG} alt="Imagem" />
                    </div>
                )) : null}                
            </Slider>
        </section>
    )
}

export default SecondSlider;

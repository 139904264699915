import React from 'react';

const Map = () => (
  <section className="maps">
      <div className="def__label__box where__we__are">
        <div className="rectangle"></div>
        <p className="label">ONDE ESTAMOS</p>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3513.0901729893985!2d-52.46702868438154!3d-28.295592459103748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94e2c0715a7f0497%3A0x1e07c21a91d046a9!2sEzata%20Industrial!5e0!3m2!1spt-BR!2sbr!4v1593800832304!5m2!1spt-BR!2sbr" title="Localização" width="100%" height="600" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false"></iframe>
  </section>
)

export default Map;
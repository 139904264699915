import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/layout"
import client from '../components/utils/client'

// comps 
import FirstSlider from "./homepage/FirstSlider";
import SecondSlider from "./homepage/SecondSlider";
import AreaCards from "./homepage/AreaCards";
import MainContent from "./homepage/MainContent";
import BrandCards from "./homepage/BrandCards";
import Map from "./homepage/Map";

// data 
// axios.defaults.baseURL = process.env.GATSBY_API_URL;

const IndexPage = () => {  
    const [allData, setAllData] = useState([]);

    useEffect(() => {
        const handleGetData = () => {
            client()
                .get("content-group/8")
                .then(res => {
                    let data = res.data || [];
                    data.Contents.map(item => {
                        if(item.Type === "image") {
                            let obj = Object.assign(item);
                            obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                            return obj;
                        }
                    })                    
                    setAllData(data);
                })
                .catch(err =>{
                    console.log(err);
                })
        }        

        handleGetData();
    }, []);

    return (
        <Layout infoPage={{ page: "home" }}>
            <div className="home">
                <FirstSlider data={allData} />
                <AreaCards data={allData} />
                <MainContent data={allData} />
                <BrandCards data={allData} />
                <SecondSlider data={allData} />
                <Map />
            </div>
        </Layout>
    )
}
export default IndexPage;